import { MenuItem } from "presentation/view/components/OverflowMenuButton";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";

import { CriteriaEntity } from "domain/entity/Criteria/CriteriaEntity";
import { GroupRadioList } from "veronica-ui-component/dist/component/core/GroupRadioButton/GroupRadioButton";
import { ConstraintDefEntity } from "domain/entity/Criteria/ConstraintDefEntity";
import { EMPTY_POOL_EXCL_CRITERIA_ENTITY, EmptyPoolExclCriteriaEntity } from "domain/entity/EmptyPoolExclusion/EmptyPoolExclCriteriaEntity";

export interface EmptyExclCriteriaDropdownOptions {

    chargeIndDropdownOptions: DropdownProps[],
    chgTypeDropdownOptions: DropdownProps[],
    subChgTypeDropdownOptions: { [key: string]: DropdownProps[] },
    consDefDropdownOptions: DropdownProps[],
    consValueDropdownOptions: { [key: string]: DropdownProps[] },

    opsLineDropdownOptions: DropdownProps[],
    chargeOnCompanyDropdownOptions: DropdownProps[],
    cntrSizeDropdownOptions: DropdownProps[],
    cntrTypeDropdownOptions: DropdownProps[],
    cntrStatusDropdownOptions: DropdownProps[],
    operatingTmlDropdownOptions: DropdownProps[],
    locationTypeDropdownOptions: DropdownProps[],

}
export interface EmptyExclCriteriaChangeState extends BaseViewChangeSate {
    editingCriteriaEntity: EmptyPoolExclCriteriaEntity,
}

export interface EmptyExclCriteriaModel {
    isLoading: boolean,
    isShowDetail: boolean,
    isShowCriteriaPanel: boolean,
    criteriaEntityList: EmptyPoolExclCriteriaEntity[],
    currentSelectedRow: EmptyPoolExclCriteriaEntity,
    selectedRows: CriteriaEntity[],
    dynamicOptions: EmptyExclCriteriaDropdownOptions,
    isRefreshInitData: boolean,
    subMenuItemArray: MenuItem[],
    isShowMoveToPanel: boolean,
    masterState: EmptyExclCriteriaChangeState,
    entrypoint:string,
    chargeTypes?:  string[] | null,
    subChargeTypes?:  string[] | null,
    consGrpsTypeDropdownOptions: GroupRadioList[],
    allConstraintDef:ConstraintDefEntity[],
}

export const EMPTY_POOL_EXCL_MODEL: EmptyExclCriteriaModel = {
    isLoading: false,
    isShowDetail: false,
    isShowCriteriaPanel: false,
    criteriaEntityList: [],
    currentSelectedRow: { ...EMPTY_POOL_EXCL_CRITERIA_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        chargeIndDropdownOptions: [],
        chgTypeDropdownOptions: [],
        subChgTypeDropdownOptions: {},
        consDefDropdownOptions: [],
        consValueDropdownOptions: {},

        opsLineDropdownOptions: [],
        chargeOnCompanyDropdownOptions: [],
        cntrSizeDropdownOptions: [],
        cntrTypeDropdownOptions: [],
        cntrStatusDropdownOptions: [],
        operatingTmlDropdownOptions: [],
        locationTypeDropdownOptions: [],

    },
    isRefreshInitData: true,
    subMenuItemArray: [],
    isShowMoveToPanel: false,
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingCriteriaEntity: EMPTY_POOL_EXCL_CRITERIA_ENTITY
    },
    entrypoint:"",
    chargeTypes: [],
    subChargeTypes: [],
    consGrpsTypeDropdownOptions: [],
    allConstraintDef: [],
}