import { EMPTY_CRITERIA_CONSGRP_ENTITY } from "domain/entity/Criteria/ConstraintGroupEntity";
import { EMPTY_POOL_EXCL_CRITERIA_ENTITY, EmptyPoolExclCriteriaEntity } from "domain/entity/EmptyPoolExclusion/EmptyPoolExclCriteriaEntity";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { EmptyPoolExclCriteriaRepository } from "domain/repository/EmptyPoolExclCriteria/EmptyPoolExclCriteriaRepo";
import { OpsLineRepository } from "domain/repository/OpsLine/OpsLineRepo";
import { ParameterDetailRepository } from "domain/repository/parameter/ParameterDetailRepo";
import { TerminalRepository } from "domain/repository/Terminal/TerminalRepo";
import _ from "lodash";
import { CntrStatusDroOpts } from "presentation/constant/DropDownOptions/Company/CntrStatusDroOpts";
import { EmptyExclCriteriaModel } from "presentation/model/EmptyPoolExclusionCriteria/EmptyPoolExclusionCriteriaModel";
import { Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";
import { emptyPoolExclusionValidationSchema } from "presentation/constant/EmptyPoolExclusion/EmptyPoolExclusionValidationSchema";
import { Validation } from "presentation/constant/Validation";

interface CriteriaVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<EmptyExclCriteriaModel>> | ((value: SetStateAction<EmptyExclCriteriaModel>) => void),
    ],
    emptyPoolExclRepo: EmptyPoolExclCriteriaRepository,
    opsLineRepo: OpsLineRepository,
    companyRepo: CompanyRepository,
    parameterDetailRepo: ParameterDetailRepository,
    terminalRepo: TerminalRepository,
}

export const EmptyPoolExclusionCriteriaVM = ({dispatch, emptyPoolExclRepo, opsLineRepo, companyRepo, parameterDetailRepo, terminalRepo}:CriteriaVMProps) => {
    const [criteriaDispatch] = dispatch;

    const onRowDoubleClick = (entity: EmptyPoolExclCriteriaEntity) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: entity,  
                masterState:{
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
            }
        })
    }
    
    const loadDropdownOption = async () => {
        await opsLineRepo.getAllOpsLines().then(
            opsLines => {
                const opsLinesDropdownOptions = opsLines?.map((opsLineEntity) => ({
                    dropdownLabel:opsLineEntity.opsLine,
                    tagLabel: opsLineEntity.opsLine,
                    value: opsLineEntity.opsLine,
                })) ?? []

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        opsLineDropdownOptions: opsLinesDropdownOptions
                    }
                }))
            }
        )
        
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeOnCompanyDropdownOptions: [
                            ...companyCodeDropdownOptions],
                    }
                }))
            }
        )
        
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_SIZE").then(
            cntrSizeList => {
                const sizeDropdownOption = cntrSizeList?.map((cntrSize) => ({
                    dropdownLabel: cntrSize.parameterDtlCode,
                    tagLabel: cntrSize.parameterDtlCode,
                    value: cntrSize.parameterDtlCode,
                })) ?? []

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cntrSizeDropdownOptions: sizeDropdownOption
                    }
                }))
            }
        )

        await parameterDetailRepo.getAllParameterDtlsByParameterCode("CNTR_TYPE").then(
            cntrTypeList => {
                const typeDropdownOption = cntrTypeList?.map((cntrType) => ({
                    dropdownLabel: cntrType.parameterDtlCode,
                    tagLabel: cntrType.parameterDtlCode,
                    value: cntrType.parameterDtlCode,
                })) ?? []

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        cntrTypeDropdownOptions: typeDropdownOption
                    }
                }))
            }
        )
        
        await terminalRepo.getTerminalsForComboBox().then(
            terminalList => {
                const terminalDropdownOptions = terminalList?.map((terminal) => ({
                    dropdownLabel: terminal.terminalCode,
                    tagLabel: terminal.terminalCode,
                    value: terminal.terminalCode,
                })) ?? []

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        operatingTmlDropdownOptions: terminalDropdownOptions
                    }
                }))
            }
        )
        
        await parameterDetailRepo.getAllParameterDtlsByParameterCode("LOCATION_TYPE").then(
            locationTypeList => {
                const locationTypeDropdownOption = locationTypeList?.map((locationType) => ({
                    dropdownLabel: locationType.parameterDtlCode,
                    tagLabel: locationType.parameterDtlCode,
                    value: locationType.parameterDtlCode,
                })) ?? []

                criteriaDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        locationTypeDropdownOptions: locationTypeDropdownOption
                    }
                }))
            }
        )
        
        criteriaDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                cntrStatusDropdownOptions: CntrStatusDroOpts().getDroOptsModel()
            }
        }))
    }

    const searchEmptyPoolExclusion = async() => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isRefreshInitData:false,
                selectedRows: [],
                criteriaEntityList: [],
                currentSelectedRow: {...EMPTY_POOL_EXCL_CRITERIA_ENTITY},
            }
        })
        await emptyPoolExclRepo.getEmptyPoolExclusion().then((data) => {
            data = data?.filter(data => data.exclusionType === 'EmptyPoolExclusionEntity')
            criteriaDispatch(prevState => {
                return {
                    ...prevState,
                    criteriaEntityList: data,
                    selectedRows: [],
                }
            })
        }).catch((error) => {
            return [];
        })

    }

    const onHeaderFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        criteriaDispatch(prevState => {

            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    editingCriteriaEntity: {
                        ...prevState.masterState.editingCriteriaEntity,
                        [fieldKey]: val,
                    },
                    allFormState:{
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }
            }
        })
    }

    const onSave =  async(currentCriteriaEty: EmptyPoolExclCriteriaEntity, isAdd: boolean) => {
        
        const valHdrResult = await Validation(emptyPoolExclusionValidationSchema).ValidateFormOnly(currentCriteriaEty);
        
        if (valHdrResult ) {
            let validatedTariffResult: {[x: string]: string} = {};  
            if (valHdrResult) {  
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };  
            }  

            criteriaDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }                        
                }
            });
            return validatedTariffResult;
        } else {

            let res = null;
            if(isAdd){
                res = await emptyPoolExclRepo.createNewCriteria(currentCriteriaEty);
            }else{
                res = await emptyPoolExclRepo.updateCriteria(currentCriteriaEty);
            }
            
            if(res.success){    
                onCloseClick();
            }else{
                return res.data;
            }
        }
    }

    const onCloseClick = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isRefreshInitData:true,
                selectedRows:[],
                currentSelectedRow: {...EMPTY_POOL_EXCL_CRITERIA_ENTITY},        
                masterState:{
                    ...prevState.masterState,
                    isAdd:false,
                    isEditable:false,
                    isRead: true,
                    isTabularDataActive: true,
                    isSliderOpen: false,
                    editingCriteriaEntity: EMPTY_POOL_EXCL_CRITERIA_ENTITY,
                },
            }
        });
    }

    const onSaveClicked =() => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    
    const onEdit = (currentTarCodeEty: EmptyPoolExclCriteriaEntity)=>{
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true, 
                    editingCriteriaEntity:{
                        ...currentTarCodeEty,
                    }
                },
            }
        })
    }
    
    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        criteriaDispatch(prevState => ({
            ...prevState,
            masterState:{
                ...prevState.masterState,
                editingCriteriaEntity : {
                    ...prevState.masterState.editingCriteriaEntity,
                    [fieldName]: checked?"Y":"N",
                }
            }
        }))
    }

    const onCancel = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,
                    currentSelectItem: EMPTY_CRITERIA_CONSGRP_ENTITY,
                    isSliderOpen: false,
                    allFormState:{}
                }
            }
        })
    }

    const onComponentSaveClicked =() => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const updateSelectedComponentRows = async (rows: any[]) => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                selectedRows: rows,
            }
        })
    }

    const onAdd = () => {
        criteriaDispatch(prevState => {
            return {
                ...prevState,
                masterState:{
                    ...prevState.masterState,  
                    editingCriteriaEntity: EMPTY_POOL_EXCL_CRITERIA_ENTITY,   
                    isAdd:true,
                    isSliderOpen: true,
                    isEditable:false,
                    isRead: false,
                    isSaveClicked: false,
                }
            }
        })
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        
        if (_.isArray(val)) {
          val = _.uniq(val?.map((item: any) => item.value || item.key));
        }     
  
        
        criteriaDispatch(prevState => {
            return {
                ...prevState, 
                masterState:{
                    ...prevState.masterState,
                    editingCriteriaEntity: {
                        ...prevState.masterState.editingCriteriaEntity,
                        [fieldKey]: val,
                    },
                    allFormState:{
                        ...prevState.masterState.allFormState,
                        [fieldKey]: '',
                    }
                }          
            }
        });
    }

    return {
        onHeaderFieldChange: onHeaderFieldChange,
        loadDropdownOption: loadDropdownOption,
        onRowDoubleClick: onRowDoubleClick,
        searchEmptyPoolExclusion: searchEmptyPoolExclusion,
        onSave: onSave,
        onCloseClick: onCloseClick,
        onSaveClicked: onSaveClicked,
        onEdit: onEdit,
        onCheckboxChange: onCheckboxChange,
        onCancel: onCancel,
        onAdd: onAdd,
        onComponentSaveClicked: onComponentSaveClicked,
        onFieldChange: onFieldChange,
        updateSelectedComponentRows: updateSelectedComponentRows,
    }
}